import React from 'react'
import { Container, Image, Row } from 'react-bootstrap'

const FastPixelShader = () => {
    return (
        <Container>
            <Row style={{width: '100%', justifyContent: 'center', marginBottom: '3%' }}>
                <Image
                    style={{
                        width: '850px',
                    }}
                    src={require('../assets/fastpixelshader-logo.png')}
                />
            </Row>
            <Row style={{ marginTop: 15 }}>
            <p>
                <h4>FAST PIXEL SHADER - PIXEL PERFECT STYLIZATION</h4>
                Transform your scene with a highly customizable pixel shader, featuring adjustable <b>Color Quantization</b>, <b>Dithering</b>, <b>Pixelation</b>, <b>Scanlines</b>, <b>and Edge Smoothing</b>.
            </p>
            <div>
                <div>
                    <h5>HOW TO USE:</h5>
                    <p>
                        <ul>
                            <li>Create pixel material instance or use the master material.</li>
                            <li>Add a Post Process Volume to your scene.</li>
                            <li>Add the material to the Post Process Materials section.</li>
                            <li>Resize Post Process Volume or enable Infinite Extent option to affect entire scene.</li>
                            <li>Press Play!</li>
                        </ul>
                    </p>
                </div>
                <div>
                    <h5>EASY TO CUSTOMIZE:</h5>
                    <p>Create the perfect pixel effect for your scene using exposed parameters in the material instance:</p>
                    <p>
                        <ul>
                            <li><b>Color Quantization</b> - Limit colors to achieve a classic 8-bit or 16-bit look, or increase for a smoother effect.</li>
                            <li><b>Dithering</b> - Add dithering patterns for a more authentic pixel feel.</li>
                            <li><b>Pixelation</b> - Adjust pixel size to achieve anything from a subtle retro effect to heavily blocky visuals.</li>
                            <li><b>Scanlines</b> - Add CRT-style scanlines for an old-school arcade look.</li>
                            <li><b>Edge Smoothing</b> - Soften sharp edges to create a smoother transition between pixels.</li>
                        </ul>
                    </p>
                </div>
                <div>
                    <h5>THE PACKAGE INCLUDES:</h5>
                    <p>
                        <ul>
                            <li><b>Customizable Pixel Shader Master Material</b></li>
                            <li><b>Adjustable Parameters for Full Control</b></li>
                            <li><b>Clean, Commented Blueprint for Easy Extensibility</b></li>
                            <li><b>Demo Scene with All Features Showcased</b></li>
                        </ul>
                    </p>
                </div>
            </div>
                <p>
                    <a href='https://www.fab.com/'>Fast Pixel Shader - FAB Marketplace</a>
                </p>
            </Row>
            {/* <Row style={{width: '100%', marginTop: '1%', flexDirection: 'row',  display: 'inline-block'}}>
                <h3>Related Files</h3>
                <a href='https://www.herocode.us/mobilestatustoolkit/mobilestatustoolkit.pdf'>Mobile Status Toolkit Documentation</a>
            </Row> */}
        </Container>
    )
}

export default FastPixelShader